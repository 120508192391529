<template>
  <v-app id="app">
    <router-view />
  </v-app>
  <metainfo>
  </metainfo>
  <vue-cookie-accept-decline :debug="false" :disableDecline="false" :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline"
    @clicked-postpone="cookieClickedPostpone" @removed-cookie="cookieRemovedCookie" @status="cookieStatus"
    elementId="cookies_modal" position="bottom-left" ref="myPanel1" transitionName="slideFromBottom" type="floating">
    <template #postponeContent>&times;</template>

    <template #message>
      <p class="cookies_modal_title mb-0">We use cookies </p>
      <p class="cookies_modal_subtitle mb-0">We use Google Analytics to analyze website traffic </p>
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" disabled color="#CF9634" base-color="#fff" class="text-white" label="Necessary"
            value="1" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white"
            label="Preferences" value="2" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white" label="Statistics"
            value="3" hide-details density="compact"></v-checkbox>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-checkbox v-model="selected_cookies" color="#CF9634" base-color="#fff" class="text-white" label="Marketing"
            value="4" hide-details density="compact"></v-checkbox>
        </v-col>
      </v-row>
    </template>

    <template #acceptContent>ACCEPT</template>

    <template #declineContent>DECLINE </template>
  </vue-cookie-accept-decline>
</template>

<script>
import { useMeta } from 'vue-meta'
import { useLocale } from "vuetify";
import { watch } from 'vue';

export default {
  name: 'App',
  data: () => ({
    selected_cookies: ["1", "2", "3", "4"],
  }),
  setup() {
    let data = {
      ru: {
        title: "MoneyBreeze - Обменная касса валют",
        description: "Обмен валюты по выгодным курсам в MoneyBreeze в Кишиневе. Быстро, безопасно и без скрытых комиссий. Удобное расположение и профессиональные консультации. Позвоните нам по телефону 0765 56 601 для получения актуальной информации о курсах и услугах.",
      },
      ro: {
        title: "MoneyBreeze - Casa valutara",
        description: "Schimb valutar la cursuri avantajoase la MoneyBreeze din Chișinău. Rapid, sigur și fără comisioane ascunse. Locație convenabilă și consultanță profesională. Sunați-ne la 0765 56 601 pentru a obține informații actualizate despre cursuri și servicii.",
      },
    };

    const locale = useLocale();

    useMeta({
      htmlAttrs: {
        lang: locale.current.value,
      },
      title: data[locale.current.value].title,
      description: data[locale.current.value].description,
      meta: [
        { vmid: 'description', name: 'description', content: data[locale.current.value].description }
      ]
    })

    watch(locale.current, (newLocale) => {
      document.title = data[newLocale].title;
      document.documentElement.lang = newLocale;
      const metaDescription = document.querySelector('meta[name="description"]');
      (metaDescription) && metaDescription.setAttribute('content', data[newLocale].description);
    });

    return {
      locale,
    };
  },
  methods: {
    cookieStatus(status) {
      this.status = status;
      if (status == "accept") {
        this.selected_cookies = localStorage.getItem("vue-cookie-accept-decline-cookies_modal_select") ?? [];

        if (this.selected_cookies.includes("2")) {
          window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
          })
        }

        if (this.selected_cookies.includes("3")) {
          window.gtag('consent', 'update', {
            'analytics_storage': 'granted'
          })
        }

        if (this.selected_cookies.includes("4")) {
          window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_storage': 'granted',
          })
        }
        this.$gtag.optIn();
      } else {
        this.$gtag.optOut();
      }
    },
    cookieClickedAccept() {
      if (this.selected_cookies.includes("2")) {
        window.gtag('consent', 'update', {
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
        })
      }

      if (this.selected_cookies.includes("3")) {
        window.gtag('consent', 'update', {
          'analytics_storage': 'granted'
        })
      }

      if (this.selected_cookies.includes("4")) {
        window.gtag('consent', 'update', {
          'ad_user_data': 'granted',
          'ad_storage': 'granted',
        })
      }
      localStorage.setItem("vue-cookie-accept-decline-cookies_modal_select", this.selected_cookies)
      this.status = this.selected_cookies;
      this.$gtag.optIn();
    },
    cookieClickedDecline() {
      this.status = [];
    },
    cookieClickedPostpone() {
      console.log('here in postpone');
      this.status = 'postpone';
    },
    cookieRemovedCookie() {
      this.status = null;
      this.$refs.myPanel1.init();
    },
    removeCookie() {
      console.log('Cookie removed');
      this.$refs.myPanel1.removeCookie();
    },
  }
}
</script>
<style>
@import url("vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css");

#cookies_modal .cookie__floating__content {
  margin-bottom: 0px;
  max-height: unset;
}

#cookies_modal .cookie__floating__buttons__button--decline {
  background: transparent !important;
  color: #3d3d3d;
  border: unset;
  font-weight: 400;
  transition: .3s;
}

#cookies_modal .cookie__floating__buttons__button--decline:hover {
  color: #f44336;
  background: rgba(255, 255, 255, 0.1) !important;
}

#cookies_modal .cookie__floating__buttons__button--accept {
  background: transparent !important;
  color: #CF9634;
  border: unset;
  font-weight: 400;
  transition: .3s;
}

#cookies_modal .cookie__floating__buttons__button--accept:hover {
  color: #4caf50;
  background: rgba(255, 255, 255, 0.1) !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 20px;
}

html,
#app,
main {
  background: #F7F1EC;
  overflow-x: hidden;
}

@media (min-width: 1440px) {
  .v-container {
    max-width: 1400px !important;
  }
}

@media screen and (max-width: 321px) {
  #app {
    zoom: .85;
  }
}
</style>
<style scoped>
#cookies_modal {
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;

  box-shadow: unset;
}

.cookies_modal_title {
  color: #FFFFFF;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.02rem;
}

.cookies_modal_subtitle {
  color: #737475;
  font-size: 1rem;
  font-weight: 400;
}
</style>