import { createRouter, createWebHistory } from "vue-router";
import { locale } from "../locales/index.js";
import store from "@/store";

const routes = [
  {
    path: "/:lang?",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/ru/" + to.hash);
      }
    },
  },
  {
    path: "/:lang?/tv",
    name: "TV",
    component: () => import("../views/TV.vue"),
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/ro/tv/" + to.hash);
      }
    },
  },
  {
    path: "/:lang?/legal/:name?",
    name: "legal",
    component: () => import("../views/Legal.vue"),
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/ru/legal" + to.hash);
      }
    },
  },
  {
    path: "/:lang?/unsubscribe/:email?/:id?",
    name: "Unsubscribe",
    component: () => import("../views/Unsubscribe.vue"),
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/ru/unsubscribe" + to.hash);
      }
    },
  },
  {
    path: "/:lang?/auth",
    name: "auth",
    component: () => import("../views/SignIn.vue"),
    beforeEnter: async (to, from, next) => {
      let responce = await store.dispatch("AdminCkeck");

      if (responce == true) {
        next({
          name: "dashboard",
        });
      } else {
        next();
      }
    },
  },
  {
    path: `/:lang?/admin`,
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    beforeEnter: async (to, from, next) => {
      let responce = await store.dispatch("AdminCkeck");

      if (responce == true) {
        next();
      } else {
        next({
          name: "auth",
        });
      }
    },
    children: [
      {
        path: "currency",
        name: "currency-crm",
        component: () => import("../views/CRM/2.Currency.vue"),
      },
      {
        path: "rate",
        name: "rate-crm",
        component: () => import("../views/CRM/1.Rate.vue"),
      },
      {
        path: "history",
        name: "history-crm",
        component: () => import("../views/CRM/3.History.vue"),
      },
      {
        path: "leads",
        name: "leads-crm",
        component: () => import("../views/CRM/4.Leads.vue"),
      },
      {
        path: "vip",
        name: "vip-crm",
        component: () => import("../views/CRM/5.Vip.vue"),
      },
      {
        path: "settings",
        name: "settings-crm",
        component: () => import("../views/CRM/6.Settings.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash, behavior: "smooth", top: 65 };
    } else if (to.query.mdl) {
      return false;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

export default router;
